
import './App.css';
import ToolSearchEbay from './view/layout';
import { SnackbarProvider, useSnackbar } from 'notistack';

function App() {
  return (
    <>
      <SnackbarProvider>
        <ToolSearchEbay />
      </SnackbarProvider>
    </>
  );
}

export default App;
