import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    ListItem,
    List,
    ListItemButton,
    ListItemText,
    IconButton,
    filledInputClasses,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Card,
    CardContent,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {
    ListItemIcon,
    Delete,
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import { DataGrid, gridClasses, useGridApiRef } from '@mui/x-data-grid';
import { fetchPostData } from "../../../helper/fetch-data";
import { SnackbarProvider, useSnackbar } from 'notistack';

const fetchEditSkypeGroup = async (editData) => {
    const result = await fetchPostData(
        new URL(
            './api/transferPostData',
            process.env.REACT_APP_HOST_MASTER_API
        ).href,
        {
            "data": editData,
            "pageCurrent": "/esearch/skypebot/wtb",
            "urlAPI": "/api/group-skype/data-save"
        },
        {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + process.env.REACT_APP_TOKEN_KEY
        }
    );
    return result;
};

const tableHeader = [
    { field: 'name', headerName: 'Group Name', editable: true, flex: 1, minWidth: 150, headerClassName: 'wtb-header-table' },
    { field: 'usernameSkype', headerName: 'Username', editable: true, flex: 1, minWidth: 150, headerClassName: 'wtb-header-table' },
    {
        field: 'id', headerName: ' ',
        sortable: false,
        headerClassName: 'wtb-header-table',
        renderCell: null // add latter
    }
];

const SkypeGroupEditor = ({ skypeGroupData, getSkypGroupData }) => {
    const apiRef = useGridApiRef();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const rowUpdate = async (newData, oldData) => {

        if (JSON.stringify(newData) === JSON.stringify(oldData)) {
            return newData;
        }

        const editedData = {
            ...newData,
            status: undefined,
            createdAt: undefined,
            _iid: undefined
        };

        (async () => {
            try {
                const res = await fetchEditSkypeGroup(editedData);
                if (!res) throw Error('delete fail');
                enqueueSnackbar(`Updated ${newData.name}`, { variant: 'success' });
            }
            catch (error) {
                enqueueSnackbar(`Update Error ${newData.name}`, { variant: 'error' });
            }
        })();

        return newData;
    }
    const rowUpdateError = (error) => {
        console.log(error);
    }

    const rowDelete = async (data) => {
        try {
            const deletedData = { id: data.id, status: 'deleted',  removeGroupSkypeId: data?.row.groupSkypeId}
            const res = await fetchEditSkypeGroup(deletedData);
            apiRef.current.updateRows([{ id: data.id, _action: 'delete' }]);
            enqueueSnackbar(`Deleted ${data.row.name}`, { variant: 'success' });
        }
        catch (error) {
            enqueueSnackbar(`Delete Error ${data.row.name}`, { variant: 'error' });
        }
    }

    const rowHidden =  async (data) => {
        try {
            const hiddenData = { 
                id: data?.row?.id ,
                groupSkypeId: data?.row.groupSkypeId, 
                name: data?.row?.name,
                usernameSkype: data?.row?.usernameSkype,
                hidden: !data?.row?.hidden,
                updatedAt: data?.row?.updatedAt,
                createdById: data?.row?.createdById,
                updatedById: data?.row?.updatedById
             }

            const res = await fetchEditSkypeGroup(hiddenData);

            if(res?.Status === 'OK'){
                enqueueSnackbar(`${data?.row?.hidden ? 'Hidden group' : 'Show group'} "${data.row.name}" successful`, { variant: 'success' });
            }else{
                enqueueSnackbar(`${data?.row?.hidden ? 'Hidden group' : 'Show group'} "${data.row.name}" Error ${res?.Msg}`, { variant: 'error' });
            }
            getSkypGroupData()

        } catch (error) {
            enqueueSnackbar(`${data?.row?.hidden ? 'Hidden group' : 'Show group'} ${data.row.name} Error`, { variant: 'error' });
        }
    }

    tableHeader[2].renderCell = (params) => (
        <>
            <IconButton
                onClick={() => rowDelete(params)}
                color="error"
            >
                <Delete />
            </IconButton>
            <IconButton
                onClick={() => rowHidden(params)}
                color="primary"
            >
                {params?.row.hidden ? <Tooltip title="Show"><Visibility /></Tooltip>   : <Tooltip title="Hidden"><VisibilityOff /></Tooltip> }
            </IconButton>
        </>
    );

    return (
        <>
            <DialogTitle >Skype Group Editor</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        width: '1000px', maxWidth: '100%',
                        '& .wtb-header-table': {
                            backgroundColor: 'rgb(241 244 249)',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold'
                        },
                        [`.${gridClasses.cell}.blur`]: {
                            backgroundColor: '#bdc3c7',
                        },
                    }}
                >
                    <DataGrid
                        apiRef={apiRef}
                        rows={skypeGroupData}
                        columns={tableHeader}
                        // initialState={{
                        //     pagination: {
                        //         paginationModel: { page: 0, pageSize: 5 },
                        //     },
                        // }}
                        pageSizeOptions={[]}
                        processRowUpdate={rowUpdate}
                        onProcessRowUpdateError={rowUpdateError}
                        getCellClassName={(params) => {
                            return params?.row?.hidden  ? 'blur' : '';
                          }}
                    />
                </Box>
            </DialogContent>
        </>
    );
}

export default SkypeGroupEditor;